import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';

function Root() {
    useEffect(() => {
        if (process.env.NODE_ENV === "production" && 'serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(function(registrations) {
                for(let registration of registrations) {
                    registration.unregister()
                }
            });
        }
    }, []);

    return (
        <App />
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));
