import React, { Component } from 'react'
import '../../styles/css/SideMenu.css'
import '../../styles/css/AdminMain.css'
import { app } from '../../base';
import logoWithTextImage from '../../styles/images/sqwad_logo_white.svg';

class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customClaims: null,  // State to store custom claims
    };
    this.logOut = this.logOut.bind(this);
  }

  componentDidMount() {
    // Get the custom claims and save to state
    app.auth().currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          const customClaims = idTokenResult.claims || {};
          // Save the custom claims to state
          this.setState({ customClaims });
        })
        .catch((error) => {
          console.log('Error getting custom claims:', error);
        });
  }

  logOut() {
    app.auth().signOut().then((user, error) => {
      this.setState({ redirect: true });
    });
  }

  render() {
    const homeLink = "/admin";
    const scratcherGameLink = "/setupgame";
    const ticketsLink = "/setuptickets";
    const teamVariables = "/setupteamvariables";
    const rulesAndRegs = "/setuprulesandregs";
    const ticketEmail = "/setupticketemail";
    const loginVariables = "/setuploginvariables";
    const matchesLink = "/setupmatches";
    const scoreboardSettings = "/setupscoreboard";
    const setUpRoles = "/setuproles";

    // Check if the user has the scoreboard claim
    const { customClaims } = this.state;
    const hasScoreboardClaim = customClaims && customClaims.scoreboard;

    return (
        <div className="admin-sidebar">
          <div className="logo">
            <a href={homeLink}>
              <div className="logo-img"><img src={logoWithTextImage} height="30" alt=""/></div>
            </a>
          </div>
          <div className="nav-container">
            <ul className="nav">
                <li className="side-menu-items">
                  <a href={homeLink}>
                    <span className="fa fa-home pre_side_item"/>
                    <span className="">Dashboard</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
              <li className="">
                <a href={scratcherGameLink}>
                  <span className="fa fa-gamepad pre_side_item"/>
                  <span className="">Games</span>
                  <span className="fa fa-chevron-right after_fa_side"/>
                </a>
              </li>
              <li className="">
                <a href={matchesLink}>
                  <span className="fa fa-clone pre_side_item"/>
                  <span className="">Matches</span>
                  <span className="fa fa-chevron-right after_fa_side"/>
                </a>
              </li>
              <li className="">
                <a href={ticketsLink}>
                  <span className="fa fa-trophy pre_side_item"/>
                  <span className="">Prizes</span>
                  <span className="fa fa-chevron-right after_fa_side"/>
                </a>
              </li>
              <li className="">
                <a href={teamVariables}>
                  <span className="fa fa-pencil-square-o pre_side_item"/>
                  <span className="">Game Branding</span>
                  <span className="fa fa-chevron-right after_fa_side"/>
                </a>
              </li>
              <li className="">
                <a href={rulesAndRegs}>
                  <span className="fa fa-legal pre_side_item"/>
                  <span className="">Rules And Regs</span>
                  <span className="fa fa-chevron-right after_fa_side"/>
                </a>
              </li>
              <li className="">
                <a href={ticketEmail}>
                  <span className="fa fa-envelope-open-o pre_side_item"/>
                  <span className="text">Email Branding</span>
                  <span className="fa fa-chevron-right after_fa_side"/>
                </a>
              </li>
              <li className="">
                <a href={loginVariables}>
                  <span className="fa fa-user pre_side_item"/>
                  <span className="text">Fan Login</span>
                  <span className="fa fa-chevron-right after_fa_side"/>
                </a>
              </li>
              <li className="">
                <a href={scoreboardSettings}>
                  <span className="fa fa-tv pre_side_item"/>
                  <span className="text">Scoreboard Settings</span>
                  <span className="fa fa-chevron-right after_fa_side"/>
                </a>
              </li>
              {!hasScoreboardClaim && (
                <li className="">
                  <a href={setUpRoles}>
                    <span className="fa fa-users pre_side_item"/>
                    <span className="text">Roles</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
              )}
              <div className="bottom-buttons">
                <li className="" onClick={this.logOut}>
                  <a href={homeLink}>
                    <span className="fa fa-sign-out pre_side_item"/>
                    <span className="text">Logout</span>
                    <span className="fa fa-chevron-right after_fa_side"/>
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </div>
    );
  }
}

export default SideMenu;
