import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import { base, appDatabasePrimaryFunctions } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import DatePicker from '../utils/DatePicker';
import {cleanCSVText, convertTimeStampToHumanReadable, getSnapshotFromEndpoint, findFirstAndLastName, returnTimeStampInMilliseconds} from '../utils/HelpfulFunction';

class SetUpGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketList: [],
            matchesList: [],
            matchesAdded: [],
            rewardsAdded: [],
            codes: false,
            rewardToAdd: '',
            rewardAmount: 0,
            codesArray: '',
            qrCodes: false,
            tenantVariables: {},
            emailsSent: {},
            activeSquareCodes: {},
            emailVariables: {},
            tenantRules: {},
            id_key: "",
            addedItems:[],
            currentGame: null,
            modal: false,
            userGameHistory: [],
            users: [],
            gameName: "",
            loading: true,
            wizardLocation: 'first',
            showStats: true,
            endTime: new Date(),
            startTime: new Date(),
            itemsPerPage: 25,
            itemsToShow: 25,
            deleteAddedItems: false,
            userAnswers: [],
            randomOrder: false,
            gameTiming: "immediate",
        };
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
        this.saveReward = this.saveReward.bind(this);
    }

    getCurrentTime(datePassedIn){
        if(!datePassedIn){
            datePassedIn = new Date();
        }
        return `${datePassedIn.getFullYear()}-${`${datePassedIn.getMonth() + 1}`.padStart(2, 0)}-${`${datePassedIn.getDate()}`.padStart(2, 0)}T${`${datePassedIn.getHours()}`.padStart(2, 0)}:${`${datePassedIn.getMinutes()}`.padStart(2, 0)}`;
    }

    componentDidMount() {
        const vm = this;
        this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
        });
        this.emailVariablesRef = base.bindToState(`emailVariables`, {
            context: this,
            state: 'emailVariables',
        });
        this.tenantRulesRef = base.bindToState(`tenantRules`, {
            context: this,
            state: 'tenantRules',
        });
        this.rewardsListRef = base.syncState(`rewardsList`, {
            context: this,
            state: 'ticketList',
            asArray: true
        });
        this.userAnswersRef = appDatabasePrimaryFunctions.ref("userAnswers").on('value', function(snapshot){
            vm.setState({
                userAnswerCount: snapshot.numChildren()
            })
        })
        this.currentGameRef = base.syncState(`currentGame`, {
            context: this,
            state: 'currentGame',
            then(err){
                if(!err){
                    // this.setGameToWatch(this.state.currentGame.id);
                }
                this.setState({loading:false})
            }
        });
        this.matchesListRef = base.syncState(`matchesList`, {
            context: this,
            state: 'matchesList',
            asArray: true
        });
    }

    downloadUsers(){
        this.setState({loading: true});
        const vm = this;
        let csv = 'Email,First Name,Last Name,Phone Number,Zip Code,Address,Birthday,How You Heard?,Opt-In,Opt-In 2,High Score,Reward Sent,Code\n';
        Promise.all([getSnapshotFromEndpoint('users', appDatabasePrimaryFunctions)]).then(async function(snapshots){
            let users = {};
            if(snapshots[0].exists() && snapshots[0].val()){
                users = snapshots[0].val();
            }
            const currentUsersState = await vm.organizeUserAnswers();
            currentUsersState.forEach(function(row) {
                let {firstName, secondName} = findFirstAndLastName(users[row.uid].name);
                csv += (users[row.uid].email || row.uid || "");
                csv+= ",";
                csv+= (cleanCSVText(firstName) || "");
                csv+= ",";
                csv+= (cleanCSVText(secondName) || "");
                csv+= ",";
                csv+= (users[row.uid].phoneNumber || "");
                csv+= ",";
                csv+= (cleanCSVText(users[row.uid].zipCode) || "");
                csv+= ",";
                csv+= (cleanCSVText(users[row.uid].address) || "");
                csv+= ",";
                csv+= (users[row.uid].birthday || "");
                csv+= ",";
                csv+= (cleanCSVText(users[row.uid].howYouHeard) || "");
                csv+= ",";
                csv+= (users[row.uid].optIn || "");
                csv+= ",";
                csv+= (users[row.uid].optInTwo || "");
                csv+= ",";
                csv+= (row.highScore || "");
                csv+= ",";
                csv+= (cleanCSVText(row.rewardSent) || "NONE");
                csv+= ",";
                csv+= (cleanCSVText(row.code) || "-");
                csv += "\n";
            });
            const hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
            hiddenElement.target = '_blank';
            const date_got = new Date((new Date().getTime() - new Date().getTimezoneOffset()*60*1000)).toISOString().split("T")[0] + ".csv";
            hiddenElement.download = "current_game_users_" + date_got;
            hiddenElement.click();
            vm.setState({loading: false})
        })
    }

    componentWillUnmount() {
        if(this.userGameHistoryRef){
            appDatabasePrimaryFunctions.ref('userGameHistory').off('value', this.userGameHistoryRef);
        }
        appDatabasePrimaryFunctions.ref('userAnswers').off('value', this.userAnswersRef);
        base.removeBinding(this.tenantVariablesRef);
        base.removeBinding(this.rewardsListRef);
        base.removeBinding(this.matchesListRef);
        base.removeBinding(this.currentGameRef);
        base.removeBinding(this.tenantRulesRef);
        base.removeBinding(this.emailVariablesRef);
    }

    detectHowManyCodesEntered(codesArray){
        if(!codesArray){
            return [];
        }
        codesArray = codesArray.trim();
        const splitOnLineBreaks = codesArray.split("\n");
        let splitOnWhiteSpace = codesArray.split(/\s+/);
        let splitOnCommas = codesArray.split(",");
        let splitArray = splitOnLineBreaks;
        if(splitOnWhiteSpace.length === splitOnCommas.length){
            splitOnWhiteSpace = codesArray.replace(/,/g, '').split(/\s+/);
            splitOnCommas = codesArray.replace(/\s/g,'').split(",");
        }
        if(splitArray.length < splitOnWhiteSpace.length){
            splitArray = splitOnWhiteSpace
        }
        if(splitArray.length < splitOnCommas.length){
            splitArray = splitOnCommas
        }
        return splitArray
    }

    async createGame(){
        let createGameObject = {};
        let currentGame = this.state.currentGame || {};
        const gameId = appDatabasePrimaryFunctions.ref().push().key;
        const matchesAdded = this.state.matchesAdded || [];
        const futureGames = this.state.futureGamesList || [];
        let gameTiming = this.state.gameTiming;
        if(!matchesAdded || matchesAdded.length < 2){
            swal({
                title: "Hold On!",
                text: 'Need to add more matches to the game!',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        let startTime = this.state.startTime;
        let endTime = this.state.endTime;
        if(!startTime || !endTime){
            swal({
                title: "Hold On!",
                text: 'The game needs a start time and end time!',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        startTime = startTime.getTime();
        endTime = endTime.getTime();
        if(startTime >= endTime){
            swal({
                title: "Hold On!",
                text: 'The game start time cannot be equal to or greater than the game end time!',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        if(startTime <= Date.now()){
            swal({
                title: "Hold On!",
                text: 'The game start time cannot be before now!',
                type: 'warning',
                confirmButtonText: 'Ok'
            });
            return
        }
        for(const fGameIndex in futureGames){
            const futureGame = futureGames[fGameIndex];
            if(startTime === futureGame.scheduleInfo.performAt){
                swal({
                    title: "Hold On!",
                    text: "You already have a scheduled game at that time!",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return;
            }
        }
        let gameName = this.state.gameName.trim();
        let {rewardsAdded} = this.state;
        if(!gameName){
            swal({
                title: "Hold On!",
                text: 'The game needs a name!',
                type: 'warning',
                confirmButtonText: 'Ok'
              });
            return
        }
        let readyToUploadPrizes = null;
        if(rewardsAdded.length !== 0){
            let totalAmount = 0;
            readyToUploadPrizes = {};
            for(const rewardIndex in rewardsAdded){
                readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key] = rewardsAdded[rewardIndex].reward;
                readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['amount'] = parseInt(rewardsAdded[rewardIndex].amount);
                totalAmount+=parseInt(rewardsAdded[rewardIndex].amount);
                readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['codes'] = rewardsAdded[rewardIndex].codes;
                readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['qrCodes'] = rewardsAdded[rewardIndex].qrCodes;
                readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['codesArray'] = rewardsAdded[rewardIndex].codesArray || [];
                readyToUploadPrizes[rewardsAdded[rewardIndex].reward.key]['used'] = 0;
            }
            readyToUploadPrizes["totalRewards"] = totalAmount;
            readyToUploadPrizes["totalRewardsUsed"] = 0;
            readyToUploadPrizes["randomPrizeOrder"] = this.state.randomOrder;
        }
        this.setState({loading:true})
        createGameObject.gameName =  gameName;
        createGameObject.id = gameId;
        createGameObject.active =  false;
        createGameObject.ended =  false;
        createGameObject.gameType =  "memory";
        createGameObject.rewards = readyToUploadPrizes;
        createGameObject.scheduleInfo = {};
        createGameObject.scheduleInfo['status'] = 'scheduled';
        createGameObject.scheduleInfo['performAt'] = startTime;
        createGameObject.scheduleInfo['endAt'] = endTime;
        let shouldReplaceCurrentGame  = true;
        createGameObject.matches = matchesAdded;
        createGameObject.gameTiming = gameTiming;
        if(currentGame.gameName){
            shouldReplaceCurrentGame = false;
        }
        const vm = this;
        if(shouldReplaceCurrentGame){
            this.setState({
                currentGame: createGameObject,
                loading: false,
                modal: false
            })
        } else {
            this.setState({
                loading: true
            })
            base.post('futureGamesList/'+ createGameObject.id, {
                data: createGameObject,
                then(err){
                    if(!err){
                        vm.setState({
                            modal: false
                        })
                    }
                    vm.setState({
                        loading: false
                    })
                }
            })
        }
    }

    stopGame(){
      let currentGame = this.state.currentGame;
      currentGame.active = false;
      this.setState({
        currentGame: currentGame
      })
      swal({
        title: 'Game Stopped',
        type: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: true,
        timer: 10000
      })
    }

    startGame() {
        let currentGame = this.state.currentGame;
        this.setState({loading:true})
        currentGame.timeStamp = Date.now()
        currentGame.active = true;
        this.setState({
            currentGame: currentGame,
            loading: false
        });
        swal({
            title: 'Game Started',
            type: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
        })
    }

    getSnapshotFromEndpoint(endpoint, numberOfResults=null, searchData=null, orderBy=null) {
        let baseRef = appDatabasePrimaryFunctions.ref(endpoint);
        if(orderBy){
            baseRef = baseRef.orderByChild(orderBy)
        }
        if(numberOfResults){
            baseRef = baseRef.limitToFirst(numberOfResults);
        }
        return baseRef.once('value').then(function(snapshot){
            return snapshot;
        })
    }

    async organizeUserAnswers(numberOfResults=0, searchData=null){
        let playedUsers = [];
        await Promise.all([this.getSnapshotFromEndpoint('userAnswers', numberOfResults, searchData, 'timeStamp'), this.getSnapshotFromEndpoint('emailsSent'), this.getSnapshotFromEndpoint('users')]).then(async function(snapshots) {
            let userAnswers = snapshots[0];
            let emailsSent = snapshots[1].val() || {};
            let users = snapshots[2].val() || {};
            userAnswers.forEach(function(userAnswerDataRef){
                const iteratedAnswerData = userAnswerDataRef.val() || {};
                let userId = iteratedAnswerData.uid;
                let userAnswer = iteratedAnswerData.numberOfMoves || "";
                let userEmail = "No Email Collected";
                if(users[userId] && users[userId].email) {
                    userEmail = users[userId].email
                }
                iteratedAnswerData['email'] = userEmail;
                iteratedAnswerData['highScore'] = userAnswer;
                iteratedAnswerData['timeStamp'] = iteratedAnswerData.timeStamp || "";
                iteratedAnswerData['uid'] = iteratedAnswerData.uid || userId || "";
                iteratedAnswerData['solved'] = userAnswer ? "Yes" : "No";
                const emailsSentObject = emailsSent[btoa(userEmail)] || emailsSent[userId]
                if (emailsSentObject) {
                    iteratedAnswerData['rewardSent'] = emailsSentObject.rewardName || "";
                    iteratedAnswerData['code'] = emailsSentObject.code || "";
                }
                if(searchData){
                    const cleanSearchData = searchData.toLowerCase();
                    const lowerCaseEmail = userEmail.toLowerCase();
                    let lowerCaseRewardName = "";
                    if(emailsSent[userId] && emailsSent[userId].name){
                        lowerCaseRewardName = emailsSent[userId].name.toLowerCase();
                    }
                    if(lowerCaseEmail.indexOf(cleanSearchData) !== -1  || lowerCaseRewardName.indexOf(cleanSearchData) !== -1){
                        playedUsers.push(iteratedAnswerData);
                    }
                } else {
                    playedUsers.push(iteratedAnswerData);
                }
            })
        })
        playedUsers.sort(function(a,b){
            // Sort by score descending, then by timestamp ascending
            if (a.highScore !== b.highScore) {
                return a.highScore - b.highScore;
            }
            return a.timeStamp - b.timeStamp;
        });
        return playedUsers;
    }

    addMatchToLocalArray(){
        let selected_match = null;
        const matchesAddedArray = this.state.matchesAdded;
        const matchesList = this.state.matchesList;
        for(const matchAddedIndex in matchesAddedArray){
            if(matchesAddedArray[matchAddedIndex].key === this.state.selected_match){
                swal({
                    title: "Match Already In Game!",
                    text: "This game already includes this match. Try adding another match.",
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return;
            }
        }
        for(const matchIndex in matchesList){
            if(matchesList[matchIndex].key === this.state.selected_match){
                selected_match = matchesList[matchIndex];
                selected_match['id'] = matchesList[matchIndex].key;
            }
        }
        if(selected_match){
            matchesAddedArray.push(selected_match);
            this.setState({
                matchesAdded: matchesAddedArray
            })
        }
    }

    handleChange (evt) {
      let target = evt.target;
      let value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({ [evt.target.name]: value });
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        wizardLocation: "first",
        matchesAdded: [],
        gameName: "",
        rewardsAdded: [],
        codes: false,
        rewardToAdd: '',
        rewardAmount: 0,
        codesArray: '',
        qrCodes: false,
      });
    }

    resetGame(){
      swal({
          title: 'STOP!',
          text: 'Are you sure you want to do this?  This will erase all information gathered during this game! Only do this if no one has played yet.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'YES I WANT TO RESET THE GAME',
        }).then(async (result)=>{
          if(result.value){
              const currentGameStateRef = await appDatabasePrimaryFunctions.ref('currentGame').once('value');
              const currentGameState = currentGameStateRef.val() || {};
              currentGameState.active = false;
              if(currentGameState.rewards){
                  currentGameState.rewards.totalRewardsUsed = 0;
              }
              for(const index in currentGameState.rewards){
                  if(typeof currentGameState.rewards[index] === 'object'){
                      currentGameState.rewards[index].used = 0
                  }
              }
              let history = await appDatabasePrimaryFunctions.ref(`userGameHistory`).orderByChild(currentGameState.id).equalTo(currentGameState.id).once('value');
              history = history.val();
              for(let historyRef in history){
                  let refInstance = history[historyRef];
                  if(refInstance && refInstance[currentGameState.id]){
                      await appDatabasePrimaryFunctions.ref(`userGameHistory/${historyRef}/${currentGameState.id}`).set(null);
                  }
              }
              await appDatabasePrimaryFunctions.ref("emailsSent").set(null);
              await appDatabasePrimaryFunctions.ref("userAnswers").set(null);
              await appDatabasePrimaryFunctions.ref("scoreboardData").set(null);
              this.setState({
                  currentGame: currentGameState,
                  currentUserAnswersState: []
              })
          }
        })
    }

    navButtonClicked(direction){
      const currentLocation = this.state.wizardLocation;
      if(direction === 'prev' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'first'
        });
        this.toggleWizardLocation('first', 'second', 'third', 'fourth')
      } else if(direction === 'prev' && currentLocation === 'third'){
        this.setState({
          wizardLocation: 'second'
        });
        this.toggleWizardLocation('second', 'first', 'third', 'fourth')
      } else if(direction === 'next' && currentLocation === 'first'){
        this.setState({
          wizardLocation: 'second',
        });
        this.toggleWizardLocation('second', 'first', 'third', 'fourth')
      } else if(direction === 'next' && currentLocation === 'second'){
        this.setState({
          wizardLocation: 'third',
        });
        this.toggleWizardLocation('third', 'first', 'second', 'fourth')
      } else if(direction === 'next' && currentLocation === 'second'){
            this.setState({
              wizardLocation: 'fourth',
            });
            this.toggleWizardLocation('fourth', 'first', 'second', 'third')
      } else if(direction === 'next' && currentLocation === 'third'){
          this.setState({
              wizardLocation: 'fourth',
          });
          this.toggleWizardLocation('fourth', 'first', 'second', 'third')
      } else if(direction === 'prev' && currentLocation === 'fourth'){
          this.setState({
              wizardLocation: 'third',
          });
          this.toggleWizardLocation('third', 'first', 'second', 'fourth')
      } else if(direction === 'prev' && currentLocation === 'fourth'){
          this.setState({
              wizardLocation: 'second',
          });
          this.toggleWizardLocation('second', 'first', 'fourth', 'third')
      } else {
        this.setState({
          wizardLocation: 'first'
        });
        this.toggleWizardLocation('first', 'second', 'third', 'fourth')
      }
    }

    toggleWizardLocation(tabClicked, otherTab1, otherTab2, otherTab3){
      this.setState({
        wizardLocation: tabClicked
      });
      document.getElementById(tabClicked).classList.add('active');
      document.getElementById(tabClicked).classList.add('show');
      document.getElementById(otherTab1).classList.remove('active');
      document.getElementById(otherTab1).classList.remove('show');
      document.getElementById(otherTab2).classList.remove('active');
      document.getElementById(otherTab2).classList.remove('show');
      document.getElementById(otherTab3).classList.remove('active');
      document.getElementById(otherTab3).classList.remove('show');
      document.getElementById(tabClicked+'1').classList.add('active');
      document.getElementById(tabClicked+'1').classList.add('show');
      document.getElementById(otherTab1+'1').classList.remove('active');
      document.getElementById(otherTab1+'1').classList.remove('show');
      document.getElementById(otherTab2+'1').classList.remove('active');
      document.getElementById(otherTab2+'1').classList.remove('show');
      document.getElementById(otherTab3+'1').classList.remove('active');
      document.getElementById(otherTab3+'1').classList.remove('show');
    }

    async switchStatsPrizes(buttonClicked){
        if(buttonClicked === "showStats"){
            document.getElementById('showPrizes').classList.remove('active');
            document.getElementById('showGames').classList.remove('active');
            document.getElementById('showStats').classList.add('active');
            this.setState({
                showStats:true,
                showPrizes:false,
                showGames:false
            })
        }else if(buttonClicked === "showPrizes"){
            document.getElementById('showPrizes').classList.add('active');
            document.getElementById('showStats').classList.remove('active');
            document.getElementById('showGames').classList.remove('active');
            this.setState({
                loading: true
            })
            const organizeUserAnswers = await this.organizeUserAnswers();
            this.setState({
                showStats:false,
                showPrizes:true,
                showGames:false,
                loading: false,
                currentUserAnswersState: organizeUserAnswers
            })
        } else if(buttonClicked === "showGames"){
            document.getElementById('showPrizes').classList.remove('active');
            document.getElementById('showGames').classList.add('active');
            document.getElementById('showStats').classList.remove('active');
            const futureGameList = await this.getFutureGames();
            this.setState({
                showStats:false,
                showPrizes:false,
                showGames:true,
                futureGamesList: futureGameList
            })
        }
    }

    saveReward (evt) {
        this.setState({ rewardToAdd: evt.target.value });
    }

    addRewardToLocalArray(){
        const rewardAmount = this.state.rewardAmount;
        const rewardId = this.state.rewardToAdd;
        const codes = this.state.codes;
        const qrCodes = this.state.qrCodes;
        let codesArray = this.state.codesArray;
        codesArray = this.detectHowManyCodesEntered(codesArray);
        if(codesArray.length === 0 && codes){
            swal({
                title: 'Hold on!',
                text: 'Must enter codes or uncheck codes box!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        } else if (codes){
            if(codesArray.length !== parseInt(rewardAmount,10)){
                swal({
                    title: 'Codes not equal!',
                    text: "# of codes must equal number of rewards created. In this case: " +  codesArray.length + " does not equal " + rewardAmount,
                    type: 'warning',
                    confirmButtonText: 'Ok'
                })
                return;
            }
        }

        if(!rewardId || !rewardAmount || rewardAmount < 1 || rewardId.length < 1){
            swal({
                title: 'Missing field!',
                text: "Missing reward or amount of reward.  Can't add a reward to a game without an amount or selecting a reward",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        }

        for(var ticket in this.state.ticketList){
            if(this.state.ticketList[ticket].key === rewardId){
                var reward = this.state.ticketList[ticket]
                var rewardToAddObject = {};
                rewardToAddObject.reward = reward;
                rewardToAddObject.amount = rewardAmount;
                rewardToAddObject.codes = codes;
                rewardToAddObject.qrCodes = qrCodes;
                rewardToAddObject.codesArray = codesArray;
            }
        }
        var array = this.state.rewardsAdded;
        var rewardsAddedIds = []
        for(var prize in array){
            rewardsAddedIds.push(array[prize].reward.key)
        }
        if(rewardsAddedIds.indexOf(rewardId) === -1){
            array = array.concat(rewardToAddObject)
            this.setState({
                rewardsAdded: array,
                qrCodes: false,
                codes: false
            })
        } else {
            swal({
                title: 'Prize already added!',
                text: "You have already added this prize",
                type: 'warning',
                confirmButtonText: 'Ok'
            })
        }
    }

    removeFromToAddArray(index){
        let rewardArray = this.state.rewardsAdded;
        rewardArray.splice(index, 1)
        this.setState({
            rewardsAdded: rewardArray
        })
    }

    async removeGameFromFutureList(gameToRemoveId){
        var futureGameList = this.state.futureGamesList;
        for(var gameIndex in futureGameList){
            var futureGame = futureGameList[gameIndex];
            if(futureGame.id === gameToRemoveId){
                futureGameList.splice(gameIndex, 1)
                if(gameToRemoveId){
                    base.remove('futureGamesList/'+gameToRemoveId)
                }
            }
        }
        this.setState({
            futureGamesList: futureGameList
        })
    }

    async getFutureGames(numberOfResults = 10){
        return await base.fetch('futureGamesList', {
            context: this,
            asArray: true,
            queries: {
                orderByChild: 'scheduleInfo/performAt'
            },
            then(data){
                return data
            }
        })
    }

    async saveDeleteCurrentGameDataReplace(currentGame, createGameObject, fromMakeFutureGameCurrent=false){
        this.setState({
            loading: true
        })
        currentGame['users'] = this.state.userAnswerCount || 0;
        currentGame['prizesWon'] = await appDatabasePrimaryFunctions.ref('emailsSent').once('value').then(function (snapshot) {
            return snapshot.numChildren();
        });
        const organizeUserAnswers = await this.organizeUserAnswers();
        base.post(`userAnswersHistory/${currentGame.id}`, {
            data: organizeUserAnswers,
            then(data){
                console.log("User Answers Saved!")
            }
        })

        await base.remove("prizeWon");
        await base.remove("userAnswers");
        await base.remove("currentGame/rewards");
        await base.remove("currentGame/scheduleInfo");

        const vm = this;
        base.push('gamesList', {
            data: currentGame,
            then(err){
                if(!err){
                    vm.setState({
                        currentGame: createGameObject,
                        currentUserAnswersState: [],
                        modal: false
                    }, ()=>{
                        base.post(`emailsSent`, {
                            data: null,
                            then(err){
                                if(!err){
                                    console.log("success")
                                }
                            }
                        })
                        if(fromMakeFutureGameCurrent){
                            vm.removeGameFromFutureList(createGameObject.id)
                        }
                    })
                }
                vm.setState({
                    loading: false
                })
            }
        })
    }

    async sendPrizes(){
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/sendPrizes`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/memorygamedev/us-central1/api/sendPrizes`
        }
        const bearerToken = await this.props.currentUser.getIdToken();
        const bearerTokenString = "Bearer " + bearerToken;
        this.setState({loading: true});
        let fetchObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': bearerTokenString
            },
            body: JSON.stringify({})
        }
        let response;
        try{
            response = await fetch(url, fetchObject);
        } catch (e) {
            this.setState({loading: false});
            console.log("ERROR: ", e);
            return false;
        }
        const responseJson = await Promise.resolve(response.json());
        this.setState({loading: false});
        console.log(responseJson)
        swal({
            title: 'Prizes Sent!',
            type: 'success',
            toast: true,
            position: 'top-end',
            showConfirmButton: true,
            timer: 10000
        })
    }

    render() {
        const matchesAdded = this.state.matchesAdded;
        let selectedGame = this.state.currentGame || null;
        const futureGamesList = this.state.futureGamesList || [];
        const rewardsAdded = this.state.rewardsAdded || [];
        let rewardsList = [];
        let gameName = null;
        let currentUserAnswersState = this.state.currentUserAnswersState || [];
        // let players = this.state.userGameHistory || 0;
        let userAnswersCount = this.state.userAnswerCount || 0;
        let startTimeFirstPart = null;
        let startTimeSecondPart = null;
        let endTimeSecondPart = null;
        let endTimeFirstPart = null;
        let gameScheduledStatus = "completed";
        let gameTiming = null;
        let randomPrizeOrder = null;
        if(selectedGame && selectedGame.gameName){
            randomPrizeOrder = (selectedGame.rewards && selectedGame.rewards.randomPrizeOrder) ? "True" : "False";
            for(const i in selectedGame.rewards){
                const reward = selectedGame.rewards[i];
                if(typeof reward === "object"){
                    rewardsList.push(reward);
                }
            }
            if(selectedGame.scheduleInfo){
                let performAtTime = returnTimeStampInMilliseconds(selectedGame.scheduleInfo.performAt);
                let endAtTime = returnTimeStampInMilliseconds(selectedGame.scheduleInfo.endAt);
                startTimeFirstPart = new Date(performAtTime).toLocaleDateString();
                startTimeSecondPart = new Date(performAtTime).toLocaleTimeString();
                endTimeFirstPart = new Date(endAtTime).toLocaleDateString();
                endTimeSecondPart = new Date(endAtTime).toLocaleTimeString();
                gameScheduledStatus = selectedGame.scheduleInfo.status
            }
            gameName = selectedGame.gameName;
            gameTiming = selectedGame.gameTiming;
        } else {
            selectedGame = null;
        }
        const vm = this;
      return (
        <div className="admin-wrapper">
          <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
          <SideMenu/>
          <TopMenu/>
          <div className="admin-main-panel">
            <div className="container" style={{padding:'20px', backgroundColor:'#e3eaef'}}>
              <div className="row">
                <div className="col-md-3">
                  <div className="card" style={{backgroundColor:'#00c78c', width:'100%', textAlign: 'center', height:'50px', display: selectedGame && selectedGame.active ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Game Live</p>
                  </div>
                  <div className="card" style={{backgroundColor:'#fe3b4b', width:'100%', textAlign: 'center', height:'50px', display: selectedGame && !selectedGame.active ? '' : 'none', float:'left'}}>
                    <p style={{lineHeight:'50px'}}>Game Not Live</p>
                  </div>
                </div>
                <div className="col-md-3">
                    <button onClick={() => this.stopGame()} className="btn btn-primary btn-lg end-game-button" style={{display: selectedGame && selectedGame.active ? '' : 'none', float:'left', height:'52px' }}>Stop Game</button>
                    <button onClick={() => this.startGame()} className="btn btn-primary btn-lg start-game-button" style={{display: selectedGame && !selectedGame.active ? '' : 'none', float:'left', height:'52px' }}>Start Game</button>
                </div>
                  {gameTiming && gameTiming === 'manual' &&
                      <div className="col-md-2">
                          <button onClick={() => this.sendPrizes()} className="btn btn-primary btn-lg end-game-button" style={{float:'left', height:'52px' }}>Send Prizes</button>
                      </div>
                  }
                  <div className="col-md-4" style={{textAlign:'right'}}>
                      <a className="btn btn-outline-danger btn-sm" onClick={() => this.resetGame()} style={{display: selectedGame ? '' : 'none', marginTop:'7px', float:'right'}}>Reset Game</a>
                      <button onClick={() => this.toggle()} className="btn btn-primary btn-lg create-game-button" style={{float:'right', marginRight:'5px'}}>New Game</button>
                  </div>
              </div>
            </div>
            <div style={{display: !selectedGame ? 'none': 'block'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4" style={{color: "black", border: "solid 1px #d3d3d3", margin:10, borderRadius: 5}}>
                            <div className="row">
                                <div className="col-md-6">
                                    <u style={{color:"#353839"}}>Starts</u>
                                    <br/>
                                    <span style={{fontSize:18}}>{startTimeFirstPart}<br/>{startTimeSecondPart}</span>
                                </div>
                                <div className="col-md-6">
                                    <u style={{color:"#353839"}}>Ends</u>
                                    <br/>
                                    <span style={{fontSize:18}}>{endTimeFirstPart}<br/>{endTimeSecondPart}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2" style={{color: "black", border: "solid 1px #d3d3d3", marginTop:10, marginBottom:10, borderRadius: 5}}>
                            <div className="row">
                                {/*<div className="col-md-6" style={{textAlign:'center'}}>*/}
                                {/*    <span style={{fontSize:12}}>Current Game Players</span>*/}
                                {/*    <footer className="value-text">{players}</footer>*/}
                                {/*</div>*/}
                                <div className="col-md-12" style={{textAlign:'center'}}>
                                    <span style={{fontSize:12}}>Fans Solved Puzzle</span>
                                    <footer className="value-text">{userAnswersCount}</footer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{display: !selectedGame ? 'none': 'block'}}>
              <div className="card-body">
                <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showStats")}>
                        <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showStats" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Game Details</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> !this.props.scoreboardOnly && this.switchStatsPrizes("showPrizes")} style={{display: this.props.scoreboardOnly && 'none' }}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showPrizes" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Results</span>
                        </a>
                    </li>
                    <li className="nav-item" onClick={()=> this.switchStatsPrizes("showGames")}>
                        <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showGames" style={{backgroundColor:'#fafbfe'}}>
                            <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                            <span className="d-none d-lg-block">Future Games</span>
                        </a>
                    </li>
                </ul>
                <div style={{display: this.state.showPrizes ? 'block' : 'none'}}>
                    <div className="export-button-styles btn btn-primary btn-lg download-button" onClick={()=>this.downloadUsers()}>
                        <span className="fa fa-arrow-circle-down"/> Download Participants
                    </div>
                    <div style={{height:'10px', width:'100%'}}/>
                  <table className="table table-striped" style={{color:'black'}}>
                    <tbody>
                      <tr>
                          <th>Email</th>
                          <th>Solved?</th>
                          <th>Time Stamp</th>
                          <th>High Score</th>
                          <th>Reward Sent</th>
                          <th>Code</th>
                      </tr>
                      {
                          currentUserAnswersState.map(function(item,i){
                            return <tr key={i}>
                                <td style={{fontFamily:'Open Sans'}}>{item.email}</td>
                                <td style={{fontFamily:'Open Sans'}}>{item.solved}</td>
                                <td style={{fontFamily:'Open Sans'}}>{item.timeStamp === ""?item.timeStamp:convertTimeStampToHumanReadable(item.timeStamp)}</td>
                                <td style={{fontFamily:'Open Sans' }}>{item.highScore}</td>
                                <td style={{fontFamily:'Open Sans' }}>{item.rewardSent || "NONE"}</td>
                                <td style={{fontFamily:'Open Sans' }}>{item.code || "NONE"}</td>
                            </tr>
                        }, this)
                      }
                    </tbody>
                  </table>
                </div>
                <div style={{display: this.state.showStats ? 'block' : 'none'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6" style={{color:'black'}}>
                                <h4 style={{textAlign:"center"}}>Details</h4>
                                <p>Game Name: {gameName}</p>
                                <p>Game Type: {gameTiming || "Immediate"}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6" style={{textAlign:"center", color:'black'}}>
                                <h4>Rewards</h4>
                                <p>Random Order: {randomPrizeOrder}</p>
                                {rewardsList.length > 0 ?
                                    rewardsList.map(function(item, index){
                                        return (
                                            <div key={index} className="row" style={{fontSize: 20, paddingTop: 10, paddingBottom: 10, textAlign:'left', color:'black', border:'solid 1px #d3d3d3', borderRadius:5}}>
                                                <div className="col-md-6">
                                                    {item.rewardName}
                                                </div>
                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                    Amount: {item.amount}
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <div style={{textAlign:'left', color:'black'}}>
                                        <p>No Rewards Added</p>
                                    </div>
                                }
                            </div>
                            <div className="col-md-6 justify-content-center" style={{textAlign:"center"}}>
                                <h4 style={{color:'black'}}>Matches</h4>
                                {selectedGame && selectedGame.matches.length > 0 &&
                                <div style={{
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: 220,
                                    outline: "1px black solid",
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    padding: 5

                                }}>
                                    {
                                        selectedGame.matches.map(function(item, index){
                                            return(
                                                <div key={index} style={{width: 60, height: 60, margin: 5}}>
                                                    <img width="60px" height="60px" src={item.image} alt=""/>
                                                </div>)
                                        })
                                    }
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                  <div style={{display: this.state.showGames ? 'block' : 'none'}}>
                      <p style={{color:'black', marginLeft:20, fontSize:20, marginTop:20}}>Future Games</p>
                      <table className="table table-striped" style={{color:'black'}}>
                          <tbody>
                          <tr>
                              <th>Name</th>
                              <th>Date</th>
                              <th>Rewards</th>
                              <th>Make Current Game</th>
                              <th>Delete Game</th>
                          </tr>
                          {
                              futureGamesList.map(function(item,i){
                                  const dateTime = convertTimeStampToHumanReadable(item.scheduleInfo.performAt)
                                  return <tr key={i}>
                                      <td style={{fontFamily:'Open Sans'}}>{item.gameName}</td>
                                      <td style={{fontFamily:'Open Sans' }}>{dateTime}</td>
                                      <td style={{fontFamily:'Open Sans' }}>{!item.rewards ? "None" : item.rewards.totalRewards ? item.rewards.totalRewards : "Unlimited"}</td>
                                      <td style={{fontFamily:'Open Sans' }}><button className="btn btn-primary" onClick={()=>this.saveDeleteCurrentGameDataReplace(this.state.currentGame, item, true)}>MAKE CURRENT</button></td>
                                      <td style={{fontFamily:'Open Sans' }}><button className="btn btn-danger" onClick={()=>this.removeGameFromFutureList(item.id)}>DELETE</button></td>
                                  </tr>
                              }, this)
                          }
                          </tbody>
                      </table>
                  </div>
              </div>
            </div>
          </div>
          <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3"> Create Game</h4>
                  <div id="rootwizard">
                    <ul className="nav nav-pills bg-dark-light nav-justified form-wizard-header mb-3">
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('first', 'second', 'third', 'fourth')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2 active show" id="first1">
                                <span className="fa fa-pencil-square-o"/>
                                <span className="d-none d-sm-inline"> The Basics</span>
                            </a>
                        </li>

                        <li className="nav-item" onClick={() => this.toggleWizardLocation('second', 'first', 'third', 'fourth')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="second1">
                                <span className="fa fa-square"/>
                                <span className="d-none d-sm-inline"> Matches</span>
                            </a>
                        </li>

                        <li className="nav-item" onClick={() => this.toggleWizardLocation('third', 'first', 'second', 'fourth')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="third1">
                                <span className="fa fa-trophy"/>
                                <span className="d-none d-sm-inline"> Add Prizes</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={() => this.toggleWizardLocation('fourth', 'first', 'second', 'third')}>
                            <a href="#" className="nav-link rounded-0 pt-2 pb-2" id="fourth1">
                                <span className="fa fa-sign-out"/>
                                <span className="d-none d-sm-inline"> Finish</span>
                            </a>
                        </li>
                    </ul>

                    <div className="tab-content mb-0 b-0" style={{fontFamily:'Roboto'}}>

                        <div className="tab-pane active show" id="first">
                            <form id="accountForm" method="post" action="#" className="form-horizontal">
                                <div className="row">
                                    <div className="col-12">
                                      <div className="form-group row mb-3">
                                        <label className="col-md-3 col-form-label" htmlFor="gameName"> Game Name</label>
                                        <div className="col-md-9">
                                          <input id="gameName" name="gameName" type="text" className="form-control" value={this.state.gameName} onChange={this.handleChange} placeholder="12/11 vs MonStars" />
                                        </div>
                                      </div>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="startTime"> Set Start Time</label>
                                            <div className="col-md-9">
                                                <DatePicker showTimeInput dateFormat="Pp" selected={this.state.startTime} onChange={date => this.setState({startTime: date})}/>
                                            </div>
                                        </div>
                                        <div className="form-group row mb-3">
                                            <label className="col-md-3 col-form-label" htmlFor="endTime"> Set End Time</label>
                                            <div className="col-md-9">
                                                <DatePicker showTimeInput dateFormat="Pp" selected={this.state.endTime} onChange={date => this.setState({endTime: date})}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group row mb-3">
                                            <label className="col-md-12 col-form-label" htmlFor="name3"> Answer Timing</label>
                                            <div className="col-md-9" onChange={this.handleChange}>
                                                <input type="radio" value="manual" name="gameTiming" checked={this.state.gameTiming === "manual"}/> <span style={{marginRight:10, fontSize:10}}>Manual</span>
                                                <input type="radio" value="immediate" name="gameTiming" checked={this.state.gameTiming === "immediate"}/> <span style={{fontSize:10}}>Immediate</span>
                                                <p className="text-muted2" style={{fontSize:'10px', display: this.state.gameTiming === "manual" ? 'block' : 'none'}}>"Manual" requires a button click to start send prizes.</p>
                                                <p className="text-muted2" style={{fontSize:'10px', display: this.state.gameTiming === "immediate" ? 'block' : 'none'}}>"Immediate" sends prizes as soon as the user solves the puzzle if appropriate</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="tab-pane" id="second">
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group" align="center">
                                        <label htmlFor="scrambleImage" style={{width:'100%'}}>Add Matches</label>
                                        <select className="form-control" name="selected_match" id="selected_match" value={this.state.selected_match} onChange={this.handleChange}>
                                            <option/>
                                            {
                                                this.state.matchesList.map(function(item,index){
                                                    return(
                                                        <option value={item.key} key={index}>{item.matchName}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row mb-3" align="center">
                                <div className="col-md-12">
                                    <button className="btn btn-primary btn-admin" onClick={()=>this.addMatchToLocalArray()}>Add Match</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12" align="center">
                                    {matchesAdded.length > 0 &&
                                        <div style={{
                                            width: 220,
                                            outline: "1px black solid",
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            padding: 5
                                        }}>
                                            {
                                                matchesAdded.map(function(item, index){
                                            return(
                                                    <div key={index} style={{width: 60, height: 60, margin: 5}}>
                                                    <img width="60px" height="60px" src={item.image} alt=""/>
                                                    </div>)
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                      <div className="tab-pane" id="third">
                        <div className="form-horizontal">
                          <div className="row">
                            <div className="col-12">
                                <div className="form-group row mb-3">
                                    <label className="col-md-3 col-form-label" htmlFor="name3"> Select Reward</label>
                                    <div className="col-md-9">
                                        <select className="form-control" onChange={this.saveReward}>
                                            <option/>
                                            {
                                                this.state.ticketList.map(function(item,index){
                                                    return(
                                                        <option value={item.key} key={index}>{item.rewardName}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group row mb-3">
                                    <label className="col-md-3 col-form-label" htmlFor="surname3"> How Many Of This Reward To Give Out?</label>
                                    <div className="col-md-9">
                                        <input id="rewardAmount" name="rewardAmount" type="number" className="form-control" value={this.state.rewardAmount} onChange={this.handleChange} placeholder="5" />
                                    </div>
                                </div>

                                <div className="form-check">
                                    <input value={this.state.codes} className="form-check-input" id="codes" name="codes" type="checkbox" checked={this.state.codes} onChange={this.handleChange} />
                                    <label className="form-check-label" htmlFor="codes">Redeem Codes?</label>
                                </div>

                                <div className="form-check" style={{display: this.state.codes ? 'flex' : 'none', marginTop: 5, marginBottom: 5}}>
                                    <input value={this.state.qrCodes} className="form-check-input" id="qrCodes" name="qrCodes" type="checkbox" checked={this.state.qrCodes} onChange={this.handleChange} />
                                    <label className="form-check-label" htmlFor="codes">Convert Codes To QRCode?</label>
                                </div>

                                <div className="form-group row mb-3" style={{display: this.state.codes ? 'flex' : 'none' }}>
                                    <label htmlFor="codesArray" className="col-md-3 col-form-label">Enter Codes (1234,1238,1230)</label>
                                    <div className="col-md-9">
                                        <textarea value={this.state.codesArray} className="form-control" name="codesArray" onChange={this.handleChange} placeholder="1235,1238,1230"/>
                                    </div>
                                </div>

                                <div className="form-group row mb-3" align="center">
                                    <div className="col-md-12">
                                        <button className="btn btn-primary btn-admin" onClick={()=>this.addRewardToLocalArray()}>Add Reward</button>
                                    </div>
                                </div>

                                {rewardsAdded && rewardsAdded.length > 1 &&
                                <div className="form-check" >
                                    <input value={this.state.randomOrder} className="form-check-input" id="randomOrder" name="randomOrder" type="checkbox" checked={this.state.randomOrder} onChange={this.handleChange} />
                                    <label className="form-check-label" htmlFor="randomOrder">Give Out In Random Order</label>
                                </div>
                                }

                                <div className="form-group mb-3">
                                    <ol style={{listStylePosition: 'inside', textAlign:'left'}} className="offset-md-0 col-md-10">
                                        {
                                            rewardsAdded.map(function(item,index){
                                                var codesText = "No"
                                                if(item.codes){
                                                    codesText = "Yes"
                                                }
                                                return(
                                                    <div key={index} className="form-group">
                                                        <li>) {item.amount} - {item.reward.rewardName}; Codes: {codesText} <span style={{float:'right'}} className="fa fa-trash-o" onClick={()=>vm.removeFromToAddArray(index)}/></li>
                                                    </div>
                                                )
                                            })
                                        }
                                    </ol>
                                </div>

                            </div>
                          </div>
                        </div>
                      </div>

                        <div className="tab-pane fade" id="fourth">
                            <form id="otherForm" method="post" action="#" className="form-horizontal"/>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="text-center">
                                            <h2 className="mt-0">
                                                <i className="mdi mdi-check-all"/>
                                            </h2>
                                            <h3 className="mt-0">Finish</h3>

                                            <div className="row form-group">
                                              <div className="col-md-12">
                                                <p>You are all set to create a game!  Before clicking "Create Game" make sure all the settings are the way you want. Feel free to navigate back to edit anything you like!</p>
                                              </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                        </div>

                        <ul className="list-inline wizard mb-0">
                            <li className="previous list-inline-item" style={{display: this.state.wizardLocation === 'first' ? 'none' : '' }}><a href="#" className="btn btn-primary btn-lg previous-wizard-button" onClick={() => this.navButtonClicked('prev')}>Previous</a></li>
                            <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg next-wizard-button" onClick={() => this.navButtonClicked('next')} style={{display: this.state.wizardLocation === 'fourth' ? 'none' : '' }}>Next</a></li>
                            <li className="next list-inline-item float-right"><a href="#" className="btn btn-primary btn-lg creategame-wizard-button" onClick={() => this.createGame()} style={{display: this.state.wizardLocation === 'fourth' ? '' : 'none' }}>Create Game</a></li>
                        </ul>

                      </div>
                    </div>
                </div>
              </div>
          </Modal>
       </div>
      );
    }
}

export default SetUpGame;
