import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';

class SetUpScoreboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otherBlockedNames: [],
            loading: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.scoreboardSettingsRef = base.listenTo(`scoreboardSettings`, {
            context: this,
            then(data){
                this.setState({
                    otherBlockedNames: data.otherBlockedNames,
                    loading: false
                })
            },
            onFailure(e){
                console.log(e)
                this.setState({
                    loading: false
                })
            }
        });
    }

    componentWillUnmount() {
        base.removeBinding(this.scoreboardSettingsRef);
    }

    handleSubmit(event) {
        event.preventDefault();
        let otherBlockedNames = this.state.otherBlockedNames;
        const updateObject = {};
        updateObject['otherBlockedNames'] = otherBlockedNames;
        const vm = this;
        base.post("scoreboardSettings/", {
            data: updateObject,
            then(err){
                vm.setState({loading:false})
                if(!err){
                    swal({
                        title: 'Updated!',
                        type: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: true,
                        timer: 10000
                    })
                } else {
                    swal({
                        title: 'There was some error!',
                        text: 'Try again and if the problem persists try logging out and logging back in',
                        type: 'error',
                        confirmButtonText: 'Ok'
                    })
                    console.log(err)
                }
            }
        })
    }

    handleChange (evt) {
        let target = evt.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    render() {
        const fan_side_link = process.env.REACT_APP_FAN_LINK || "";
        let otherBlockedNames = this.state.otherBlockedNames;
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <div className="row" style={{width:'100%'}}>
                    <div className="col-md-11">
                        <p className="admin-header-text" style={{marginTop:'0px'}}>Leaderboard link: <span style={{fontSize:'20px'}}>{fan_side_link + "/leaderboard"}</span></p>
                    </div>
                </div>
              <div className="container-out">
                <div className="admin-form-box">
                  <form onSubmit={this.handleSubmit} id="create-game-form">
                    <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:'20px'}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                    <div className="form-group">
                      <label htmlFor="otherBlockedNames">Additional Blocked Words</label>
                      <p style={{fontSize:'10px',color:'grey', fontFamily:'Open Sans'}}>These words will be asterisked out (****), separate each word with a space</p>
                      <textarea id="otherBlockedNames" name="otherBlockedNames" className="form-control" value={otherBlockedNames} onChange={this.handleChange}/>
                    </div>
                  </form>
                </div>
              </div>
            </div>
         </div>
        );
    }
}

export default SetUpScoreboard;
