import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { app } from '../../base';
import swal from 'sweetalert2';
import logoWithTextImageBlack from "../../styles/images/new_sqwad_logo.png";
import { Column, Row } from "simple-flexbox";
import Loading from "../utils/Loading";

class ManagePasswordComponent extends Component {
    state = {
        password: '',
        oobCode: null,
        loading: false,
        message: '',
        firstTime: false,
        continueUrl: null,
        showPassword: false
    };

    componentDidMount() {
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const code = params.get('oobCode');
        const isFirstTime = params.get('firstTime');
        const url = params.get('continueUrl');

        if (url) this.setState({ continueUrl: url });
        if (isFirstTime === "true") this.setState({ firstTime: true });

        if (code) {
            this.setState({ oobCode: code });
            app.auth().verifyPasswordResetCode(code).catch(error => {
                this.setState({
                    message: 'You\'ve either already created your password for this admin or you didnt create within an hour of receiving your email. Contact your customer success manager if your token has expired.'
                });
            });
        } else {
            this.setState({ message: 'No authentication token found.' });
        }
    }

    togglePasswordVisibility = () => {
        this.setState(prevState => ({ showPassword: !prevState.showPassword }));
    }

    handleResetPassword = async (event) => {
        event.preventDefault();
        const { password, oobCode, continueUrl, firstTime } = this.state;
        const { history } = this.props;

        if (!password) {
            swal({
                title: 'Input Error',
                text: 'Please enter a new password.',
                type: 'warning',
                confirmButtonText: 'Ok',
            });
            return;
        }

        this.setState({ loading: true });

        try {
            await app.auth().confirmPasswordReset(oobCode, password);
            if (continueUrl) {
                history.push(continueUrl);
            } else {
                this.setState({ message: 'Your password has been reset successfully.' });
            }
        } catch (error) {
            console.error(error);
            this.setState({
                message: firstTime ? 'Failed to create password. Please try again.' : 'Failed to reset password. Please try again.'
            });
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        const { password, loading, message, firstTime, oobCode, showPassword } = this.state;

        return (
            <Row vertical="center" style={containerStyle}>
                {loading && <Loading loading={true} />}
                <Column>
                    <form onSubmit={this.handleResetPassword}>
                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                            <img src={logoWithTextImageBlack} alt="Sqwad Logo" height="auto" width="130px" />
                        </div>
                        <h2 style={headerStyle}>{firstTime ? 'Create Your Password' : 'Reset Your Password'}</h2>
                        <p style={subHeaderStyle}>Password should be at least 6 characters</p>
                        {message !== "You've either already created your password for this admin or you didnt create within an hour of receiving your email. Contact your customer success manager if your token has expired." &&
                            <>
                                <div style={inputGroupStyle}>
                                    <input
                                        style={{ borderRadius: 0 }}
                                        type={showPassword ? "text" : "password"}
                                        value={password}
                                        onChange={(e) => this.setState({ password: e.target.value })}
                                        className="form-control"
                                        placeholder={firstTime ? 'Create new password' : 'Enter new password'}
                                        disabled={!oobCode}
                                    />
                                    <i
                                        className={`fa ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                                        style={inputIconStyle}
                                        onClick={this.togglePasswordVisibility}
                                    ></i>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-danger"
                                    disabled={loading || !oobCode}
                                >
                                    {firstTime ? 'Create Password' : 'Reset Password'}
                                </button>
                            </>
                        }
                        {message && <p style={errorTextStyle}>{message}</p>}
                    </form>
                </Column>
            </Row>
        );
    }
}

const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    padding: '40px'
};

const inputGroupStyle = {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10
};

const inputIconStyle = {
    position: 'absolute',
    right: '15px',  // Align icon to the right
    top: '50%',  // Center icon vertically
    transform: 'translateY(-50%)',  // Ensure it's perfectly centered
    cursor: 'pointer',
    color: '#888',  // Default icon color
};

const errorTextStyle = {
    color: '#D32F2F',
    margin: '10px 0',
    fontWeight: 'bold',
    fontSize: '14px'
};

const headerStyle = {
    fontSize: 30,
    fontFamily: 'Inter',
    fontWeight: 700
};

const subHeaderStyle = {
    color: 'grey',
    fontSize: 14,
    fontWeight: 400
};

export default withRouter(ManagePasswordComponent);
