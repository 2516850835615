import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Loading from './components/utils/Loading';
import AdminHome from './components/admin/AdminHome'
import AdminLogin from './components/admin/AdminLogin'
import SetUpRewards from './components/admin/SetUpRewards';
import SetUpGame from './components/admin/SetUpGame';
import SetUpBrandingVariables from './components/admin/SetUpBrandingVariables';
import SetUpRulesAndRegs from './components/admin/SetUpRulesAndRegs';
import SetUpEmail from './components/admin/SetUpEmail';
import SetUpMatches from './components/admin/SetUpMatches';
import LoginVariables from './components/admin/SetUpLoginVariables';
import SetUpScoreboard from './components/admin/SetUpScoreboard';
import { app} from './base'
import ManagePasswordComponent from "./components/admin/ManagePasswordComponent";
import SetUpRoles from "./components/admin/SetUpRoles";

const AuthenticatedAdminRoute = ({ component: Component, authenticated, scoreboardOnly, allowedRoutes, path, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                if (authenticated) {
                    // Restrict scoreboardOnly users to allowed routes
                    if (scoreboardOnly && path === "/setuproles") {
                        return <Redirect to="/setupscoreboard" />;
                    }
                    return <Component scoreboardOnly={scoreboardOnly} {...props} {...rest} />;
                } else {
                    return <Redirect to={{ pathname: '/adminlogin', state: { from: props.location } }} />;
                }
            }}
        />
    );
};

class App extends Component {
    constructor(props) {
        super(props);
        this.setCurrentUser = this.setCurrentUser.bind(this);
        this.state = {
            authenticated: false,
            currentUser: null,
            loading: true,
        }
    }

    async setCurrentUser(user) {
        if (user) {
            try {
                const idTokenResult = await user.getIdTokenResult();
                const customClaims = idTokenResult.claims || {};
                const scoreboardOnly = customClaims.scoreboard || false;

                this.setState({
                    currentUser: user,
                    authenticated: true,
                    scoreboardOnly: scoreboardOnly,
                    loading: false
                });
            } catch (error) {
                console.error('Error fetching custom claims:', error);
                this.setState({
                    currentUser: user,
                    authenticated: true,
                    loading: false
                });
            }
        } else {
            this.setState({
                currentUser: null,
                authenticated: false,
                loading: false
            });
        }
    }

    componentDidMount() {
        this.removeAuthListener = app.auth().onAuthStateChanged(async (user) => {
            if (user) {
                await this.setCurrentUser(user);
            } else {
                this.setState({
                    authenticated: false,
                    currentUser: null,
                    loading: false,
                })
            }
        })
    }

    componentWillUnmount() {
        this.removeAuthListener()
    }

    render() {
        const { authenticated, scoreboardOnly, loading } = this.state;

        // Show a loading screen while the custom claims are being fetched
        if (loading) {
            return <Loading loading={true} />;
        }
        return (
            <BrowserRouter>
                <Switch>
                    <Route
                        exact
                        path="/adminlogin"
                        user={this.state.currentUser}
                        render={(props) => {
                            return <AdminLogin setCurrentUser={this.setCurrentUser} {...props} />
                        }} />

                    <AuthenticatedAdminRoute
                        exact
                        path="/admin"
                        authenticated={authenticated}
                        scoreboardOnly={scoreboardOnly}
                        component={AdminHome} />

                    <AuthenticatedAdminRoute
                        exact
                        path="/setupmatches"
                        authenticated={authenticated}
                        scoreboardOnly={scoreboardOnly}
                        component={SetUpMatches} />

                    <AuthenticatedAdminRoute
                        exact
                        path="/setupgame"
                        authenticated={this.state.authenticated}
                        scoreboardOnly={scoreboardOnly}
                        currentUser={this.state.currentUser}
                        component={SetUpGame} />

                    <AuthenticatedAdminRoute
                        exact
                        path="/setuptickets"
                        authenticated={this.state.authenticated}
                        scoreboardOnly={scoreboardOnly}
                        component={SetUpRewards} />

                    <AuthenticatedAdminRoute
                        exact
                        path="/setupteamvariables"
                        authenticated={this.state.authenticated}
                        scoreboardOnly={scoreboardOnly}
                        component={SetUpBrandingVariables} />

                    <AuthenticatedAdminRoute
                        exact
                        path="/setuprulesandregs"
                        authenticated={this.state.authenticated}
                        scoreboardOnly={scoreboardOnly}
                        component={SetUpRulesAndRegs} />

                    <AuthenticatedAdminRoute
                        exact
                        path="/setupticketemail"
                        authenticated={this.state.authenticated}
                        scoreboardOnly={scoreboardOnly}
                        component={SetUpEmail} />

                    <AuthenticatedAdminRoute
                        exact
                        path="/setuploginvariables"
                        authenticated={this.state.authenticated}
                        scoreboardOnly={scoreboardOnly}
                        component={LoginVariables} />

                    <AuthenticatedAdminRoute
                        exact
                        path="/setupscoreboard"
                        authenticated={this.state.authenticated}
                        scoreboardOnly={scoreboardOnly}
                        component={SetUpScoreboard} />

                    <AuthenticatedAdminRoute
                        exact
                        path="/setuproles"
                        authenticated={authenticated}
                        currentUser={this.state.currentUser}
                        scoreboardOnly={scoreboardOnly}
                        component={SetUpRoles}
                    />

                    {/* Password Management Routes */}
                    {['/resetpassword', '/setpassword', '/managepassword'].map((path, index) => (
                        <Route
                            key={index}
                            path={path}
                            render={(props) => (
                                <ManagePasswordComponent
                                    loggedIn={authenticated}
                                    setCurrentUser={this.setCurrentUser}
                                    user={this.state.currentUser}
                                    {...props}
                                />
                            )}
                        />
                    ))}

                    <Redirect to="/adminlogin"/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
