import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import {Modal, ModalBody, ModalHeader} from "reactstrap";

class SetUpRoles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            otherBlockedNames: [],
            loading: true,
            currentAdmins: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        this.getUsers();
    }

    async getUsers(){
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/getAdmins`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/getAdmins`
        }
        const bearerToken = await this.props.currentUser.getIdToken();
        const bearerTokenString = "Bearer " + bearerToken;
        this.setState({loading: true});
        let fetchObject = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': bearerTokenString
            }
        }
        let response;
        try{
            response = await fetch(url, fetchObject);
            const responseJson = await response.json();
            const activeAdmins = responseJson.admins.filter(admin =>
                !admin.disabled && !admin.email.endsWith('@sqwadhq.com')
            );
            this.setState({
                currentAdmins: activeAdmins || [],
                loading: false
            })
        } catch (e) {
            this.setState({loading: false});
            console.log("ERROR: ", e);
            return false;
        }
    }

    async deleteAdmin(uid){
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/deleteAdmin`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/deleteAdmin`
        }
        const bearerToken = await this.props.currentUser.getIdToken();
        const bearerTokenString = "Bearer " + bearerToken;
        this.setState({loading: true});
        let fetchObject = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'authorization': bearerTokenString
            },
            body: JSON.stringify({
                uid: uid
            })
        }
        let response;
        try{
            response = await fetch(url, fetchObject);
            if(response.ok){
                await this.getUsers();
                this.setState({loading: false})
                swal({
                    title: 'Admin Deleted!',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            }
        } catch (e) {
            this.setState({loading: false});
            console.log("ERROR: ", e);
        }
    }

    async resendPasswordReset(email){
        const protocol = window.location.protocol; // Get the protocol (http: or https:)
        const base = window.location.hostname; // Get the base domain
        let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/resendPasswordResetEmail`;
        if(process.env.NODE_ENV === "development"){
            url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/resendPasswordResetEmail`
        }
        const bearerToken = await this.props.currentUser.getIdToken();
        const bearerTokenString = "Bearer " + bearerToken;
        this.setState({loading: true});
        let fetchObject = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'authorization': bearerTokenString
            },
            body: JSON.stringify({
                email: email,
                location: `${protocol}//${base}`
            })
        }
        let response;
        try{
            response = await fetch(url, fetchObject);
            if(response.ok){
                await this.getUsers();
                this.setState({loading: false})
                swal({
                    title: 'Email Sent!',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            } else {
                this.setState({loading: false});
                console.log("ERROR: ", await response.json());
            }
        } catch (e) {
            this.setState({loading: false});
            console.log("ERROR: ", e);
        }
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {adminEmail, selectOption} = this.state;
        if(!adminEmail || !selectOption) {
            swal({
                title: 'Oh uh!',
                text: 'Make sure to fill in all values!',
                type: 'warning',
                confirmButtonText: 'Ok'
            })
            return;
        };
        try {
            let url = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/api/createUserWithRole`;
            if(process.env.NODE_ENV === "development"){
                url = `http://localhost:5001/${process.env.REACT_APP_FIREBASE_PROJECT_ID}/us-central1/api/createUserWithRole`
            }
            const bearerToken = await this.props.currentUser.getIdToken();
            const bearerTokenString = "Bearer " + bearerToken;
            this.setState({loading: true});
            const protocol = window.location.protocol; // Get the protocol (http: or https:)
            const base = window.location.hostname; // Get the base domain
            let response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearerTokenString
                },
                body: JSON.stringify({
                    email: adminEmail,
                    role: selectOption,
                    location: `${protocol}//${base}`
                })
            });
            this.setState({loading: false});
            if (response.ok) {
                await this.getUsers(); // Refresh the user list
                this.toggle(); // Close the modal
                swal({
                    title: 'Admin Created!',
                    type: 'success',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: true,
                    timer: 10000
                })
            } else {
                const errorText = await response.text();
                swal.fire('Error', `Failed to create admin: ${errorText}`, 'error');
            }
        } catch (error) {
            this.setState({loading: true});
            swal.fire('Error', 'An error occurred while creating the admin.', 'error');
            console.error('Error:', error);
        }
    }

    handleChange (evt) {
        let target = evt.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [evt.target.name]: value });
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
            adminEmail: null,
            selectOption: null,
            loading: false
        }));
    }

    render() {
        let currentAdmins = this.state.currentAdmins || [];
        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <div className="container-out">
                        <div className="card mt-4" style={{color: 'black'}}>
                            <div className="card-header">Admins</div>
                            <div className="card-body">
                                <button className="btn btn-primary mb-3" onClick={this.toggle}>
                                    Add New Admin
                                </button>
                                <ul className="list-group">
                                    {currentAdmins.map((currentAdmin) => {
                                        let role = 'Admin';
                                        if (currentAdmin.customClaims) {
                                            const isScoreboard = currentAdmin.customClaims['scoreboard'];
                                            if (isScoreboard) {
                                                role = 'Scoreboard Access Only';
                                            }
                                        }
                                        return (
                                            <li className="list-group-item d-flex justify-content-between align-items-center" key={currentAdmin.uid}>
                                                <span>{currentAdmin.email}, {role}</span>
                                                {this.props.currentUser.uid !== currentAdmin.uid && (
                                                    <div>
                                                        <button
                                                            className="btn btn-outline-secondary mr-2"
                                                            onClick={() => this.resendPasswordReset(currentAdmin.email)}
                                                        >
                                                            ✉️
                                                        </button>
                                                        <button
                                                            className="btn btn-outline-danger"
                                                            onClick={() => this.deleteAdmin(currentAdmin.uid)}
                                                        >
                                                            ❌
                                                        </button>
                                                    </div>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className="modal-dialog-centered">
                    <ModalHeader style={{marginLeft: 200, marginRight: 200}} toggle={this.toggle}>Add Admin</ModalHeader>
                    <ModalBody style={{padding: 40}}>
                        <form className="pl-3 pr-3" onSubmit={this.handleSubmit} id="create-email-form">
                            <div className="form-group">
                                <label htmlFor="adminEmail">Admin Email</label>
                                <input
                                    id="adminEmail"
                                    name="adminEmail"
                                    type="email"
                                    className="form-control"
                                    value={this.state.adminEmail}
                                    onChange={this.handleChange}
                                    placeholder="admin@email.com"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="selectOption">Role</label>
                                <select
                                    name="selectOption"
                                    id="selectOption"
                                    className="form-control"
                                    value={this.state.selectOption}
                                    onChange={this.handleChange}
                                >
                                    <option value="">Select Role</option>
                                    <option value="admin">Admin</option>
                                    <option value="scoreboard">Scoreboard</option>
                                </select>
                            </div>
                            <center>
                                <button type="submit" className="btn btn-primary mt-3">
                                    Submit
                                </button>
                            </center>
                        </form>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default SetUpRoles;
