import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import {appDatabasePrimaryFunctions, base} from '../../base';
import swal from 'sweetalert2';
import '../../styles/css/AdminMain.css';
import Dropzone from 'react-dropzone'

class SetUpMatches extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            matchesList: [],
            modal: false,
            matchName: "",
            emailImagePreview: {},
            matchAlternativeImagePreview: {},
            loading:true,
            files: []
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
      this.matchesListRef = base.syncState(`matchesList`, {
        context: this,
        state: 'matchesList',
        asArray: true
      });
      this.setState({loading:false})
    }

    onDrop(files, rejected, myArgument) {
      if(rejected.length > 0){
        swal({
            title: 'Image cannot be uploaded',
            text: 'Make sure the image is less than 2mbs and it is an accepted file type',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      this.setState({loading:true})

      this.UploadImage.upload_file(files[0]).then(res => {
        this.setState({loading:false})
          if(res.error){
              swal({
                  title: 'Image cannot be uploaded',
                  text: res.error,
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          } else if(res.imageUrl) {
              const nameToUpdate = myArgument + "Preview"
              const fileToUpdate = files[0]
              this.setState({
                  [myArgument]: res.imageUrl,
                  [nameToUpdate]: ({
                      fileToUpdate,
                      preview: URL.createObjectURL(fileToUpdate)
                  })
              });
          } else {
              swal({
                  title: 'Image cannot be uploaded',
                  text: "Something went wrong, please re-upload your image and try again!",
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          }
      })
    }

    componentWillUnmount() {
      // Make sure to revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(this.state.emailImagePreview.preview);
      URL.revokeObjectURL(this.state.matchAlternativeImagePreview.preview);
      base.removeBinding(this.matchesListRef);
    }

    handleChange(evt){
        let target = evt.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [target.name]: value });
    }

    handleSubmit(event) {
      event.preventDefault();
      if(this.state.loading){
        return
      }

      let matchName = this.state.matchName || "";
      if(matchName.length > 50){
        swal({
            title: 'Oh uh!',
            text: 'Match name cannot be longer than 50 characters!',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      let emailImage = this.state.emailImage || this.state.editTicketEmailImage;
      let matchImage = this.state.matchAlternativeImage || this.state.editMatchAlternativeImage;
      let advanced = this.state.advanced || false;
      if(!advanced){
          matchImage = null;
      }
      const vm = this;
      if(!matchName || !emailImage){
        swal({
            title: 'Not all fields filled out!',
            text: 'Please enter them and try again',
            type: 'warning',
            confirmButtonText: 'Ok'
          })
        return;
      }
      var rewardToSave = {}
      rewardToSave['matchName'] = matchName;
      rewardToSave['image'] = emailImage;
      rewardToSave['alternativeMatchImage'] = matchImage;
      rewardToSave['id'] = (this.state.rewardToEdit && (this.state.rewardToEdit.id || this.state.rewardToEdit.key)) || appDatabasePrimaryFunctions.ref().push().key;
      this.setState({loading:true});
        base.post(`matchesList/${rewardToSave['id']}`, {
          data: rewardToSave,
          then(err){
            if(!err){
              vm.setState({
                emailImage: null,
                matchAlternativeImage: null,
                matchAlternativeImagePreview: {},
                emailImagePreview: {},
                matchName: "",
                loading:false,
                modal: false
              })
              swal({
                  title: 'Match Created!',
                  type: 'success',
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: true,
                  timer: 10000
                })
            } else {
              vm.setState({loading:false})
              swal({
                  title: 'There was some error!',
                  text: 'Try again and if the problem persists try logging out and logging back in',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
              console.log(err)
            }
          }
        })
    }

    editTicket(event){
        event.preventDefault();
        const array = this.state.matchesList;
        const match = array[event.target.value];
        let advanced = false;
        if(match.alternativeMatchImage){
            advanced = true;
        }
        this.setState({
            modal: true,
            matchName: match.matchName,
            editTicketEmailImage:match.image,
            editMatchAlternativeImage:match.alternativeMatchImage,
            rewardToEdit: match,
            editingTicket: true,
            advanced: advanced
        });
    }

    deleteTicket(e){
      e.preventDefault();
      var array = this.state.matchesList;
      var index = array[e.target.value];
      swal({
            title: 'Delete Match?',
            text: 'Are you sure you want to do this?  You will no longer be able to use this match in any new games',
            type: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Delete',
        }).then((result)=>{
          if (result.value) {
            base.remove('matchesList/' + index.key, function(err) {
              if(err){
                swal({
                  title: 'There was some error!',
                  text: 'Try again and if the problem persists try logging out and logging back in',
                  type: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            })
          }
        })
    }

    toggle() {
      this.setState({
        modal: !this.state.modal,
        matchName: "",
        emailImagePreview: {},
        matchAlternativeImagePreview: {},
        editMatchAlternativeImage: null,
        editTicketEmailImage: null,
        advanced: false,
        editingTicket: false,
        rewardToEdit: null
      });
    }

    render() {
      const winningTicketList = this.state.matchesList;
      const emailImagePreview = this.state.emailImagePreview;
      const matchAlternativeImagePreview = this.state.matchAlternativeImagePreview;

        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
              <div className="card">
                <div className="card-body">
                    <p className="admin-header-text" style={{marginBottom:0}}>Matches (176px X 176px)</p>
                    <p className="admin-subheader-text">These are matches you can set games up with</p>
                    <button className="btn btn-primary btn-lg create-prize-button" style={{fontSize:20,marginLeft:20}} onClick={this.toggle}>Add Match</button>
                    <div className="admin-grid-container four-columns" style={{marginTop:20}}>
                      {
                        winningTicketList.map(function(item,i){
                          return <div key={i} className="card">
                              <div className="card-body" align="center">
                                <p style={{marginTop:5}}>{item.matchName}</p>
                                <p>
                                  <img width="80%" height="auto" src={item.image} alt=""/>
                                </p>
                                <button className="btn btn-primary btn-lg edit-button" style={{ marginRight:5, marginBottom:10}} onClick={this.editTicket.bind(this)} value={i}><span className="fa fa-ellipsis-v"/> Edit</button>
                                <button className="btn btn-primary btn-lg delete-button" style={{marginBottom:10}} onClick={this.deleteTicket.bind(this)} value={i}><span className="fa fa-trash-o"/> Delete</button>
                              </div>
                            </div>
                        }, this)
                      }
                    </div>
                  </div>
                </div>
              </div>
            <Modal isOpen={this.state.modal} toggle={this.toggle} style={{width: '90%'}} id="myModal">
              <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
              <ModalHeader toggle={this.toggle}>Add Match</ModalHeader>
                <ModalBody>
                  <div className="container-out">
                    <div className="question-box question-form">
                      <form className="pl-3 pr-3" onSubmit={this.handleSubmit} id="create-email-form">
                        <div className="form-group" >
                          <label htmlFor="matchName">Match Name</label>
                          <input id="matchName" name="matchName" type="text" className="form-control" value={this.state.matchName} onChange={this.handleChange} placeholder="Peanut Butter And Jelly"/>
                        </div>
                        <div className="form-group" align="center">
                          <label htmlFor="rewardEmailImage" style={{width:'100%'}}>Match Image</label>
                          <img src={this.state.editTicketEmailImage} width="auto" height="100px" style={{display: this.state.editingTicket ? '' : 'none' }} alt=""/>
                          <Dropzone
                            className="dropzone dz-clickable"
                            accept="image/*"
                            onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'emailImage')}}
                            multiple={false}
                            maxSize={2200000}>
                            <div className="dz-message needsclick">
                              <span className="fa fa-cloud-upload text-muted" style={{display: emailImagePreview.preview ? 'none' : ''}}/>
                              <h3 style={{display: emailImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                              <img
                                style={{display: emailImagePreview.preview ? '' : 'none'}}
                                src={emailImagePreview.preview}
                                width="100px"
                                height="auto"
                                alt="Drop your image here"
                              />
                            </div>
                          </Dropzone>
                        </div>
                        <div className="form-group">
                            <label htmlFor="showAdvancedSettings">Advanced</label>
                            <br/>
                            <input type="checkbox" checked={this.state.advanced} id="advanced" name="advanced" onChange={this.handleChange}/>
                        </div>
                          {this.state.advanced &&
                              <div className="form-group" align="center">
                                  <label htmlFor="editMatchAlternativeImage" style={{width:'100%'}}>
                                      Different Match Image
                                      <span style={{cursor:"pointer", display: matchAlternativeImagePreview.preview || this.state.editMatchAlternativeImage?"":"none"}} onClick={()=>this.setState({editMatchAlternativeImage: null, fileUploaded:false, matchAlternativeImagePreview: {}, matchAlternativeImage:null})}>
                                          ❌
                                      </span>
                                  </label>
                                  <img src={this.state.editMatchAlternativeImage} width="auto" height="100px" style={{display: this.state.editingTicket ? '' : 'none' }} alt=""/>
                                  <Dropzone
                                      className="dropzone dz-clickable"
                                      accept="image/*"
                                      onDrop={(accepted, rejected) => {this.onDrop(accepted, rejected, 'matchAlternativeImage')}}
                                      multiple={false}
                                      maxSize={2200000}>
                                      <div className="dz-message needsclick">
                                          <span className="fa fa-cloud-upload text-muted" style={{display: matchAlternativeImagePreview.preview ? 'none' : ''}}/>
                                          <h3 style={{display: matchAlternativeImagePreview.preview ? 'none' : ''}}>Drop files here or click to upload.</h3>
                                          <img
                                              style={{display: matchAlternativeImagePreview.preview ? '' : 'none'}}
                                              src={matchAlternativeImagePreview.preview}
                                              width="100px"
                                              height="auto"
                                              alt="Drop your image here"
                                          />
                                      </div>
                                  </Dropzone>
                              </div>
                          }
                        <div className="form-group text-center">
                          <button className="btn btn-primary btn-lg submit-button" id="submitButton">Submit</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </ModalBody>
            </Modal>
         </div>
        );
    }
}

export default SetUpMatches
