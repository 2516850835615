import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import UploadImage from '../utils/UploadImage';
import sampleAppFrontImage from '../../styles/images/front_icon.png'
import sampleAppTopImage from '../../styles/images/top_icon.png'
import appBackgroundImage from '../../styles/images/trivia_background.png'
import { base } from '../../base';
import '../../styles/css/AdminMain.css';
import swal from 'sweetalert2';
import { Modal } from 'reactstrap';
import { PhotoshopPicker } from 'react-color';
import RichTextEditor from 'html-verify-react-rte';
import {isEmptyHtmlEntity} from "../utils/HelpfulFunction";

class SetUpBrandingVariables extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            tenantVariables: {},
            showTabOneMajor: true,
            showTabTwoMajor: false,
            showTabThreeMajor: false,
            showTabOneMinor: true,
            loading:true,
            colorToShow: "#ffffff",
            colorToUpdate: "",
            colorPickerShow: false,
            colorsTab: true,
            showTabOnegameTextToggle: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentDidMount() {
      this.tenantVariablesRef = base.bindToState(`tenantVariables`, {
        context: this,
        state: 'tenantVariables',
      });
      this.setState({
        loading: false
      })
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

    handleSubmit(event) {
      event.preventDefault();
      let variables = this.state.tenantVariables || {};
        let winningMessage = variables['winningMessage'] || RichTextEditor.createEmptyValue();
        winningMessage = winningMessage.toString('html');
        if(isEmptyHtmlEntity(winningMessage)){
            winningMessage = "";
        }
        let losingMessage = variables['losingMessage'] || RichTextEditor.createEmptyValue();
        losingMessage = losingMessage.toString('html');
        if(isEmptyHtmlEntity(losingMessage)){
            losingMessage = "";
        }
        let answerSavedMessage = variables['answerSavedMessage'] || RichTextEditor.createEmptyValue();
        answerSavedMessage = answerSavedMessage.toString('html');
        if(isEmptyHtmlEntity(answerSavedMessage)){
            answerSavedMessage = "";
        }
        variables['losingMessage'] = losingMessage;
        variables['answerSavedMessage'] = answerSavedMessage;
        variables['winningMessage'] = winningMessage;
      let vm = this;
      base.post("tenantVariables/", {
        data: variables,
        then(err){
          vm.setState({loading:false})
          if(!err){
            swal({
                title: 'Tenant Variables Updated!',
                type: 'success',
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 10000
              })
          } else {
            swal({
                title: 'There was some error!',
                text: 'Try again and if the problem persists try logging out and logging back in',
                type: 'error',
                confirmButtonText: 'Ok'
              })
            console.log(err)
          }
        }
      })
    }

    handleChange (evt) {
      let tenantVariablesCopy = this.state.tenantVariables;
      tenantVariablesCopy[evt.target.name] = evt.target.value
      this.setState({ tenantVariables:  tenantVariablesCopy});
    }

    handleImageChange(event){
      let name_of_file = event.target.name;
      let target = this[name_of_file];
      let file_to_update =target.files[0];
      this.setState({loading:true})
      let vm = this;
      this.UploadImage.upload_file(file_to_update).then(res => {
          vm.setState({loading:false});
          if(res.error) {
              swal({
                  title: 'Image cannot be uploaded',
                  text: res.error,
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          } else if(res.imageUrl) {
              const tenantVariablesCopy = vm.state.tenantVariables;
              tenantVariablesCopy[name_of_file] = res.imageUrl;
              vm.setState({
                  tenantVariables: tenantVariablesCopy,
              }, () => {
                  vm[name_of_file].value = ""
              });
          } else {
              swal({
                  title: 'Image cannot be uploaded',
                  text: 'There was some issue with the image upload, please check the image type and size and try again',
                  type: 'warning',
                  confirmButtonText: 'Ok'
              })
          }
      })
    }

    handleRichTextChange = (name, text) => {
        let tenantVariablesCopy = this.state.tenantVariables;
        tenantVariablesCopy[name] = text;
        this.setState({ tenantVariables:  tenantVariablesCopy});
    }

    switchTextShowing(element){
      if(element === "first"){
        document.getElementById('showfirst').classList.add('active');
        document.getElementById('showthird').classList.remove('active');
        this.setState({
          showTabOne: true,
          showTabTwo: false,
          showTabThree: false,
        })
      }else if(element === "second"){
        document.getElementById('showfirst').classList.remove('active');
        document.getElementById('showthird').classList.remove('active');
        this.setState({
          showTabOne: false,
          showTabTwo: true,
          showTabThree: false,
        })
      } else {
        document.getElementById('showfirst').classList.remove('active');
        document.getElementById('showthird').classList.add('active');
        this.setState({
          showTabOne: false,
          showTabTwo: false,
          showTabThree: true,
        })
      }
    }

    openColorPicker(colorToChange){
        let oldColor = this.state.tenantVariables[colorToChange] || '#fff';
        this.setState({
            colorPickerShow: true,
            colorToShow: oldColor,
            colorToUpdate: colorToChange
        })
    }

    handleChangeComplete = (color) => {
        this.setState({
            colorToShow: color.hex
        })
    };

    acceptPicker(){
        let colorToChange = this.state.colorToUpdate;
        let tenantVariables = this.state.tenantVariables;
        tenantVariables[colorToChange] = this.state.colorToShow;
        this.setState({
            tenantVariables: tenantVariables,
            updateHappend: true,
            colorPickerShow: false,
        })
    }

    dismissPicker(){
        this.setState({
            colorPickerShow: false,
        })
    }

    toggleColors(){
        if(this.state.colorsTab){
            document.getElementById('showColors').classList.remove('active');
            document.getElementById('showImages').classList.add('active');
        } else {
            document.getElementById('showColors').classList.add('active');
            document.getElementById('showImages').classList.remove('active');
        }
        this.setState({colorsTab: !this.state.colorsTab})
    }

    tripleToggleControl(element, whichToggle){
        const tabOne = "showTabOne" + whichToggle;
        const tabTwo = "showTabTwo" + whichToggle;
        const tabThree = "showTabThree" + whichToggle;
        const firstElement = document.getElementById('showfirst' + whichToggle);
        const secondElement = document.getElementById('showsecond' + whichToggle);
        const thirdElement = document.getElementById('showthird' + whichToggle);
        if(element === "first"){
            firstElement.classList.add('active');
            secondElement.classList.remove('active');
            if(thirdElement){
                thirdElement.classList.remove('active');
            }
            this.setState({
                [tabOne]: true,
                [tabTwo]: false,
                [tabThree]: false,
            })
        }else if(element === "second"){
            firstElement.classList.remove('active');
            secondElement.classList.add('active');
            if(thirdElement){
                thirdElement.classList.remove('active');
            }
            this.setState({
                [tabOne]: false,
                [tabTwo]: true,
                [tabThree]: false,
            })
        } else {
            firstElement.classList.remove('active');
            secondElement.classList.remove('active');
            if(thirdElement){
                thirdElement.classList.add('active');
            }
            this.setState({
                [tabOne]: false,
                [tabTwo]: false,
                [tabThree]: true,
            })
        }
    }

    render() {
        const tenantVariables = this.state.tenantVariables || {};
        const topHeaderImage = tenantVariables.topHeaderImage || sampleAppTopImage;
        const backgroundImage = tenantVariables.backgroundImage || appBackgroundImage;
        const scoreboardBackgroundImage = tenantVariables.scoreboardBackgroundImage || "";
        const qrCodeScoreboardImage = tenantVariables.qrCodeScoreboardImage || "";
        const scoreboardImage = tenantVariables.scoreboardImage || "";
        // const scoreboardFooterImage = tenantVariables.scoreboardFooterImage || "";
        const frontImage = tenantVariables.frontLogoImage || sampleAppFrontImage;
        const cardBackImage = tenantVariables.cardBackImage;
        let winningMessage = tenantVariables.winningMessage || RichTextEditor.createEmptyValue();
        if(typeof winningMessage === 'string') {
            winningMessage = RichTextEditor.createValueFromString(winningMessage, 'html')
        }
        let losingMessage = tenantVariables.losingMessage || RichTextEditor.createEmptyValue();
        if(typeof losingMessage === 'string') {
            losingMessage = RichTextEditor.createValueFromString(losingMessage, 'html')
        }
        let answerSavedMessage = tenantVariables.answerSavedMessage || RichTextEditor.createEmptyValue();
        if(typeof answerSavedMessage === 'string') {
            answerSavedMessage = RichTextEditor.createValueFromString(answerSavedMessage, 'html')
        }
        const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' },
            ],
        };
        if(!isMlbApp){
            toolbarConfig.display.push('LINK_BUTTONS')
        }
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <p className="admin-header-text" style={{marginBottom:0, marginTop:'5px'}}>Game Branding</p>
                <p className="admin-subheader-text">This is where you can edit the game branding on your activation</p>
                <div className="container-out">
                  <div className="admin-form-box" style={{marginTop:'0px', paddingTop:'5px'}}>
                    <form onSubmit={this.handleSubmit} id="create-game-form">
                      <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:'20px'}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                      <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                          <li className="nav-item" onClick={()=> this.tripleToggleControl('first', 'Major')}>
                              <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showfirstMajor">
                                  <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Fan Color/Images</span>
                              </a>
                          </li>
                          <li className="nav-item" onClick={()=> this.tripleToggleControl('second', 'Major')}>
                              <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link" id="showsecondMajor">
                                  <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Scoreboard Color/Image</span>
                              </a>
                          </li>
                          <li className="nav-item" onClick={()=> this.tripleToggleControl('third', 'Major')}>
                              <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showthirdMajor">
                                  <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Game Text</span>
                              </a>
                          </li>
                      </ul>
                    <ul className="nav nav-tabs nav-justified nav-bordered mb-3" style={{display: this.state.showTabOneMajor === true ? '' : 'none'}}>
                        <li className="nav-item" onClick={()=> this.toggleColors()}>
                            <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showColors">
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                <span className="d-none d-lg-block">Colors</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={()=> this.toggleColors()}>
                            <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showImages">
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                <span className="d-none d-lg-block">Images</span>
                            </a>
                        </li>
                    </ul>
                    <ul className="nav nav-tabs nav-justified nav-bordered mb-3" style={{display: this.state.showTabTwoMajor === true ? '' : 'none'}}>
                        <li className="nav-item" onClick={()=> this.tripleToggleControl("first", "Minor")}>
                            <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showfirstMinor">
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                <span className="d-none d-lg-block">Colors</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={()=> this.tripleToggleControl("second", "Minor")}>
                            <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showsecondMinor">
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                <span className="d-none d-lg-block">Images</span>
                            </a>
                        </li>
                    </ul>
                    <ul className="nav nav-tabs nav-justified nav-bordered mb-3" style={{display: this.state.showTabThreeMajor === true ? '' : 'none'}}>
                        <li className="nav-item" onClick={()=> this.tripleToggleControl("first", "gameTextToggle")}>
                            <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showfirstgameTextToggle">
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                <span className="d-none d-lg-block">Winning/Losing Text</span>
                            </a>
                        </li>
                        <li className="nav-item" onClick={()=> this.tripleToggleControl("second", "gameTextToggle")}>
                            <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showsecondgameTextToggle">
                                <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                <span className="d-none d-lg-block">Actions Text</span>
                            </a>
                        </li>
                    </ul>
                      <div style={{display: this.state.showTabOneMajor ? 'block' : 'none'}}>
                          <div style={{display: this.state.colorsTab ? 'block': 'none'}}>
                            <div className="row">
                                <div className="col-md-6">
                                    <h4>Button Colors</h4>
                                    <div className="form-inline">
                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("primaryColor")}/>
                                        <div style={{backgroundColor: this.state.tenantVariables.primaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                        <label htmlFor="primaryColor" style={{marginRight: 10}}>Button Color</label>
                                        <input id="primaryColor" name="primaryColor" type="text" className="form-control" value={this.state.tenantVariables.primaryColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                        <div style={{height:'10px', width:'100%'}}/>

                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("secondaryColor")}/>
                                        <div style={{backgroundColor: this.state.tenantVariables.secondaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                        <label htmlFor="secondaryColor" style={{marginRight: 10}}>Button Text Color</label>
                                        <input id="secondaryColor" name="secondaryColor" type="text" className="form-control" value={this.state.tenantVariables.secondaryColor} onChange={this.handleChange} placeholder="#000"/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h4>Logout Color</h4>
                                    <div className="form-inline">
                                        <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("logOutButtonColor")}/>
                                        <div style={{backgroundColor: this.state.tenantVariables.logOutButtonColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                        <label htmlFor="logOutButtonColor" style={{marginRight: 10}}>Button Color</label>
                                        <input id="logOutButtonColor" name="logOutButtonColor" type="text" className="form-control" value={this.state.tenantVariables.logOutButtonColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                                    </div>
                                </div>
                            </div>
                          </div>
                          <div style={{display: this.state.colorsTab ? 'none' : 'block'}}>
                            <div className="admin-grid-container three-columns" style={{float:'left', display: 'flex', alignItems: 'flex-end'}}>
                              <div className="form-group" style={{textAlign:'center', margin:20}}>
                                  <div style={{cursor:"pointer", display: topHeaderImage?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                          ...tenantVariables,
                                          topHeaderImage: null
                                      }})}>
                                      ❌
                                  </div>
                                <img src={topHeaderImage} width="160" height="auto" alt=""/>
                                <br/>
                                <label htmlFor="topHeaderImage">Header Image</label>
                                <div className="form-group">
                                  <input style={{display:'none'}} id="topHeaderImage" name="topHeaderImage" type="file" ref={input => {this.topHeaderImage = input; }} onChange={this.handleImageChange}/>
                                  <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('topHeaderImage').click()} />
                                </div>
                              </div>
                                <div className="form-group" style={{textAlign:'center', margin:20}}>
                                    <div style={{cursor:"pointer", display: backgroundImage?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                            ...tenantVariables,
                                            backgroundImage: null
                                        }})}>
                                        ❌
                                    </div>
                                    <img src={backgroundImage} width="160" height="auto" alt=""/>
                                    <br/>
                                    <label htmlFor="backgroundImage">Phone Background Image</label>
                                    <div className="form-group">
                                        <input style={{display:'none'}} id="backgroundImage" name="backgroundImage" type="file" ref={input => {this.backgroundImage = input; }} onChange={this.handleImageChange}/>
                                        <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('backgroundImage').click()} />
                                    </div>
                                </div>
                                <div className="form-group" style={{textAlign:'center', margin:20}}>
                                    <div style={{cursor:"pointer", display: frontImage?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                            ...tenantVariables,
                                            frontImage: null
                                        }})}>
                                        ❌
                                    </div>
                                    <img src={frontImage} width="150" height="auto" alt=""/>
                                    <br/>
                                    <label htmlFor="frontLogoImage">Front Logo</label>
                                    <div className="form-group">
                                        <input style={{display:'none'}} id="frontLogoImage" name="frontLogoImage" type="file" ref={input => {this.frontLogoImage = input; }} onChange={this.handleImageChange}/>
                                        <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('frontLogoImage').click()} />
                                    </div>
                                </div>
                                <div className="form-group" style={{textAlign:'center', margin:20}}>
                                    <div style={{cursor:"pointer", display: cardBackImage?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                            ...tenantVariables,
                                            cardBackImage: null
                                        }})}>
                                        ❌
                                    </div>
                                    <img src={cardBackImage} width="150" height="auto" alt=""/>
                                    <br/>
                                    <label htmlFor="cardBackImage">Card Back (176px x 176px)</label>
                                    <div className="form-group">
                                        <input style={{display:'none'}} id="cardBackImage" name="cardBackImage" type="file" ref={input => {this.cardBackImage = input; }} onChange={this.handleImageChange}/>
                                        <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('cardBackImage').click()} />
                                    </div>
                                </div>
                            </div>
                          </div>
                      </div>
                      <div style={{display: this.state.showTabTwoMajor ? 'block' : 'none'}}>
                          <div style={{display: this.state.showTabOneMinor ? 'block': 'none'}}>
                              <div className="row">
                                  <div className="col-md-6">
                                      <h4>Header Text Colors</h4>
                                      <div className="form-inline">
                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("scoreboardFontColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.scoreboardFontColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="scoreboardFontColor" style={{marginRight: 10}}>Font Color</label>
                                          <input id="scoreboardFontColor" name="scoreboardFontColor" type="text" className="form-control" value={this.state.tenantVariables.scoreboardFontColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                          <div style={{height:'10px', width:'100%'}}/>

                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("scoreboardBackgroundFontColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.scoreboardBackgroundFontColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="scoreboardBackgroundFontColor" style={{marginRight: 10}}>Font Background Color</label>
                                          <input id="scoreboardBackgroundFontColor" name="scoreboardBackgroundFontColor" type="text" className="form-control" value={this.state.tenantVariables.scoreboardBackgroundFontColor} onChange={this.handleChange} placeholder="#000"/>
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <h4>Ranking Colors</h4>
                                      <div className="form-inline">
                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("scoreboardRankTextColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.scoreboardRankTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="scoreboardRankTextColor" style={{marginRight: 10}}>Text Color</label>
                                          <input id="scoreboardRankTextColor" name="scoreboardRankTextColor" type="text" className="form-control" value={this.state.tenantVariables.scoreboardRankTextColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div style={{display: this.state.showTabTwoMinor ? 'block': 'none'}}>
                              <div className="admin-grid-container four-columns" style={{float:'left', display: 'flex', alignItems: 'flex-end'}}>
                                  <div className="form-group" style={{textAlign:'center', margin:20}}>
                                      <div style={{cursor:"pointer", display: scoreboardBackgroundImage?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                              ...tenantVariables,
                                              scoreboardBackgroundImage: null
                                          }})}>
                                          ❌
                                      </div>
                                      <img src={scoreboardBackgroundImage} width="160" height="auto" alt=""/>
                                      <br/>
                                      <label htmlFor="scoreboardBackgroundImage">Background Image</label>
                                      <div className="form-group">
                                          <input style={{display:'none'}} id="scoreboardBackgroundImage" name="scoreboardBackgroundImage" type="file" ref={input => {this.scoreboardBackgroundImage = input; }} onChange={this.handleImageChange}/>
                                          <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('scoreboardBackgroundImage').click()} />
                                      </div>
                                  </div>
                                  <div className="form-group" style={{textAlign:'center', margin:20}}>
                                      <div style={{cursor:"pointer", display: qrCodeScoreboardImage?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                              ...tenantVariables,
                                              qrCodeScoreboardImage: null
                                          }})}>
                                          ❌
                                      </div>
                                      <img src={qrCodeScoreboardImage} width="160" height="auto" alt=""/>
                                      <br/>
                                      <label htmlFor="qrCodeScoreboardImage">QR Scoreboard Image</label>
                                      <div className="form-group">
                                          <input style={{display:'none'}} id="qrCodeScoreboardImage" name="qrCodeScoreboardImage" type="file" ref={input => {this.qrCodeScoreboardImage = input; }} onChange={this.handleImageChange}/>
                                          <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('qrCodeScoreboardImage').click()} />
                                      </div>
                                  </div>
                                  <div className="form-group" style={{textAlign:'center', margin:20}}>
                                      <div style={{cursor:"pointer", display: scoreboardImage?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                              ...tenantVariables,
                                              scoreboardImage: null
                                          }})}>
                                          ❌
                                      </div>
                                      <img src={scoreboardImage} width="160" height="auto" alt=""/>
                                      <br/>
                                      <label htmlFor="scoreboardImage">Scoreboard Image</label>
                                      <div className="form-group">
                                          <input style={{display:'none'}} id="scoreboardImage" name="scoreboardImage" type="file" ref={input => {this.scoreboardImage = input; }} onChange={this.handleImageChange}/>
                                          <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('scoreboardImage').click()} />
                                      </div>
                                  </div>
                                  {/*<div className="form-group" style={{textAlign:'center', margin:20}}>*/}
                                  {/*    <img src={scoreboardFooterImage} width="160" height="auto" alt=""/>*/}
                                  {/*    <br/>*/}
                                  {/*    <label htmlFor="scoreboardFooterImage">Scoreboard Footer Image</label>*/}
                                  {/*    <div className="form-group">*/}
                                  {/*        <input style={{display:'none'}} id="scoreboardFooterImage" name="scoreboardFooterImage" type="file" ref={input => {this.scoreboardFooterImage = input; }} onChange={this.handleImageChange}/>*/}
                                  {/*        <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('scoreboardFooterImage').click()} />*/}
                                  {/*    </div>*/}
                                  {/*</div>*/}
                              </div>
                          </div>
                      </div>
                      <div style={{display: this.state.showTabThreeMajor ? 'block' : 'none'}}>
                          <div style={{display: this.state.showTabOnegameTextToggle ? 'block': 'none'}}>
                              <div className="form-group">
                                  <label htmlFor="winningHeader">Winning Header</label>
                                  <input id="winningHeader" name="winningHeader" type="text" className="form-control" value={this.state.tenantVariables.winningHeader} onChange={this.handleChange} placeholder="You Win!"/>
                              </div>

                              <div className="form-group">
                                  <label htmlFor="winningMessage">Winning Message</label>
                                  <RichTextEditor id="winningMessage" name="winningMessage" toolbarConfig={toolbarConfig} value={winningMessage} onChange={(text)=>this.handleRichTextChange("winningMessage", text)}/>
                              </div>

                              <div className="form-group">
                                  <label htmlFor="losingHeader">No Prize Header</label>
                                  <input id="losingHeader" name="losingHeader" type="text" className="form-control" value={this.state.tenantVariables.losingHeader} onChange={this.handleChange} placeholder="You Solved The Puzzle!"/>
                              </div>

                              <div className="form-group">
                                  <label htmlFor="losingMessage">No Prize Message</label>
                                  <RichTextEditor id="losingMessage" name="losingMessage" toolbarConfig={toolbarConfig} value={losingMessage} onChange={(text)=>this.handleRichTextChange("losingMessage", text)}/>
                              </div>

                              <div className="form-group">
                                  <label htmlFor="answerSavedHeader">Manual Prize Saved Header</label>
                                  <input id="answerSavedHeader" name="answerSavedHeader" type="text" className="form-control" value={this.state.tenantVariables.answerSavedHeader} onChange={this.handleChange} placeholder="Nice Job!"/>
                              </div>

                              <div className="form-group">
                                  <label htmlFor="answerSavedMessage">Manual Prize Saved Message</label>
                                  <RichTextEditor id="answerSavedMessage" name="answerSavedMessage" toolbarConfig={toolbarConfig} value={answerSavedMessage} onChange={(text)=>this.handleRichTextChange("answerSavedMessage", text)}/>
                              </div>
                          </div>
                          <div style={{display: this.state.showTabTwogameTextToggle ? 'block': 'none'}}>
                              <div className="form-group">
                                  <label htmlFor="pageTitle">Page Title:</label>
                                  <input id="pageTitle" name="pageTitle" type="text" className="form-control" value={this.state.tenantVariables.pageTitle} onChange={this.handleChange} placeholder="Memory Match"/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="playingTextHeader">Holding Header Text:</label>
                                  <input id="playingTextHeader" name="playingTextHeader" type="text" className="form-control" value={this.state.tenantVariables.playingTextHeader} onChange={this.handleChange} placeholder="No Game Up"/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="playingTextBody">Holding Body Text:</label>
                                  <input id="playingTextBody" name="playingTextBody" type="text" className="form-control" value={this.state.tenantVariables.playingTextBody} onChange={this.handleChange} placeholder="Come back later to play"/>
                              </div>
                          </div>
                      </div>
                    </form>
                  </div>
                </div>
            </div>
              <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
                  <PhotoshopPicker onChangeComplete={ this.handleChangeComplete } color={this.state.colorToShow} onAccept={()=>this.acceptPicker()} onCancel={()=> this.dismissPicker()}/>
              </Modal>
         </div>
        );
    }
}

export default SetUpBrandingVariables;
